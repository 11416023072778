import React, { Fragment, lazy, Suspense } from 'react'
import './Login.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../utils/firebase-init';
import Logo from '../../components/Logo/Logo';
import { initalTheme } from '../../components/Swap/SwapUtils';

const SignIn = lazy(() => import('../../components/SignIn/SignIn'));
const SignUp = lazy(() => import('../../components/SignUp/SignUp'));
const Swap = lazy(() => import('../../components/Swap/Swap'));

export const Login = () => {
    const [user, loading, error] = useAuthState(auth);
    const [login, setLogin] = React.useState(true);
    initalTheme();

    return (
        <Suspense fallback={<></>}>
            <Logo />
            { login && 
                <div>
                    <SignIn children={<h1 className='prompt'><span className='promptText'>not a user?</span><a className='promptButton' onClick={() => setLogin(false)}>sign up.</a></h1>} />
                    </div>
            }
            { !login && <SignUp children={<h1 className='prompt'><span className='promptText'>already a user?</span><a className='promptButton' onClick={() => setLogin(true)}>sign in.</a></h1>} /> }
            <Swap />
        </Suspense>
    )
}