import React, { useEffect } from 'react';
import './Swap.css';
let root = document.documentElement;
var themeColor = 'light';

export const Swap = () => {

  useEffect(() => {
    const theme = window.localStorage.getItem('theme');

    if (theme === "dark") {
        root.style.setProperty("--color-primary", 'black');
        root.style.setProperty("--color-accent", '#131313');
        root.style.setProperty("--color-font", 'white');
        root.style.setProperty("--color-secondary-font", '#ffffff77');
        themeColor = 'dark';
    } else {
        root.style.setProperty("--color-primary", 'white');
        root.style.setProperty("--color-accent", '#F0F0F0');
        root.style.setProperty("--color-font", 'black');
        root.style.setProperty("--color-secondary-font", '#00000077');
        themeColor = 'light';
    }
  });

    const handleClick = () => {
        // light or dark theme
        if (themeColor === 'dark') {
            root.style.setProperty("--color-primary", 'white');
            root.style.setProperty("--color-accent", '#F0F0F0');
            root.style.setProperty("--color-font", 'black');
            root.style.setProperty("--color-secondary-font", '#00000077');
            saveTheme('light');
        }
        else {
            root.style.setProperty("--color-primary", 'black');
            root.style.setProperty("--color-accent", '#131313');
            root.style.setProperty("--color-font", 'white');
            root.style.setProperty("--color-secondary-font", '#ffffff77');
            saveTheme('dark');
        }
    };

    const saveTheme = (theme) => {
        window.localStorage.setItem('theme', theme);
        themeColor = theme;
    }

    return (
      <div id='swap' className="swap" onMouseDown={handleClick}></div>
    );
}

export default Swap;