import React from 'react';

export const Logo = () => {
    return (
        <React.Fragment>
            <style>{`
                .logo {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: var(--color-font);
                    margin: 0;
                    padding: 0;
                    margin-left: 1rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    position: absolute;
                    display: inline-block;
                    text-decoration: none;
                    z-index: 5;
                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none; /* Standard syntax */
                }
            `}</style>
            <a className='logo' href='/'>motrou.</a>
        </React.Fragment>
    )
}

export default Logo;