import { doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove, getDocs, where, collection, query } from "firebase/firestore";
import { UlidMonotonic } from "id128";
import { EditorState } from "draft-js";

import { db, auth, currentUserId } from './firebase-init';

export async function emptyUser(uid, name, email) {
    await setDoc(doc(db, "users", uid), {
        name: name,
        email: email,
        uid: uid,
        billId: '',
        active: true,
        createDate: Date.now(),
        docs: [""],
        folders: [],
        starred: [],
        trash: [],
        shared: [],
        sharedWithMeDocs: [],
        sharedWithMeFolders: [],
        sharedWithMeStarred: [],
      })
    .then(() => { })
    .catch((e) => {  console.log(e); });
}

export async function getUserDetails(uid) {
    if (uid) {
        // getDocs(collection(db, "docs")) //, where("uid", "==", uid))
        const docList = getDocs(query(collection(db, "docs"), where("owner", "==", uid)))
        .then((querySnapshot) => {
            let documentList = [];
            querySnapshot.forEach((doc) => {
                documentList.push({id: doc.id, title: doc.data().title, updated: doc.data().updated});
            });
            // sort by updated date 
            for (let i = 0; i < documentList.length; i++) {
                for (let j = 0; j < documentList.length - 1; j++) {
                    if (documentList[j].updated < documentList[j + 1].updated) {
                        const temp = documentList[j];
                        documentList[j] = documentList[j + 1];
                        documentList[j + 1] = temp;
                    }
                }
            }
            return {
                docs: documentList,
            };
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
            return "null";
        });
        return docList;
    } else {
        return "moo";
    }
}

export async function addDoc(redirect) {
    const ulid = UlidMonotonic.generate();
    const uid = currentUserId();

    await setDoc(doc(db, "docs", ulid.toRaw()), {
        title: 'Untitled',
        content: '',
        created: Date.now(),
        updated: Date.now(),
        tags: [],
        deleted: false,
        archived: false,
        pinned: false,
        starred: false,
        shared: false,
        owner: uid,
        collaborators: [],
        comments: [],
        history: [],
        attachments: [],
        links: [],
        notes: [],
        tasks: [],
        reminders: [],
        events: [],
        templates: [],
        references: [],
    })
    .then(() => { 
        if (redirect) {
         window.location.href = '/docs/' + ulid.toRaw();
        }
    })
    .catch((e) => {  console.log(e); });

    await updateDoc(doc(db, "users", uid), {
        docs: arrayUnion({id: ulid.toRaw(), title: 'Untitled', updated: Date.now()})
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });

    return ulid.toRaw();
}

export async function updateDocument(docId, docData, title, docInfo) {
    if (typeof docId === 'string' && docId.length > 0) {
        const now = Date.now();
        await updateDoc(doc(db, "docs", docId), {
            title: title,
            content: [docData],
            updated: now,
        }, { merge: true }
        )
        .then(() => { })
        .catch((e) => {  console.log(e); });
        // need to do something similar to setDeleted where the whole object is passed becuase right now it's creating a new object when we try to update the date
        // maybe just pass the whole object but first say doc.updated = now ???
        getUserDetails(currentUserId()).then((user) => {
            if (user.docs) {
                const docs = user.docs;
                docs.forEach((doc) => {
                    if (doc.id === docId) {
                        doc.updated = now;
                    }
                });
                updateDoc(doc(db, "users", currentUserId()), {
                    docs: docs,
                }, { merge: false }
                )
                .then(() => { })
                .catch((e) => {  console.log(e); });

            }
        });
    }
}

export async function getDocument(docId) {
    if (typeof docId === 'string' && docId.length > 0) {
        const docRef = doc(db, "docs", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // Add check for collaborator
            if (docSnap.data().owner === currentUserId()) {
                return docSnap.data();
            }
        }
    }
    return null;
}

export async function setDeleted(docInfo) {
    const now = Date.now();
    const docId = docInfo.id;
    await updateDoc(doc(db, "docs", docId), {
        deleted: true,
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
    await updateDoc(doc(db, "users", currentUserId()), {
        docs: arrayRemove(docInfo),
        trash: arrayUnion({id: docInfo.id, title: docInfo.title, updated: now})
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
}

export async function setArchived(docInfo) {
    const now = Date.now();
    const docId = docInfo.id;
    await updateDoc(doc(db, "docs", docId), {
        archived: true,
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
    await updateDoc(doc(db, "users", currentUserId()), {
        docs: arrayRemove(docInfo),
        folders: arrayUnion({id: docInfo.id, title: docInfo.title, updated: now})
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
}
export async function setTitle(title, docInfo) {
    const now = Date.now();
    const docId = docInfo.id;
    await updateDoc(doc(db, "docs", docId), {
        title: title,
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
    updateDoc(doc(db, "docs", docId), {
        updated: now,
    }, { merge: true }
    )
    .then(() => { })
    .catch((e) => {  console.log(e); });
}