import React, { useState, useEffect } from 'react';
import {Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import './App.css';
import {convertToRaw, convertFromRaw} from 'draft-js';
import { showConsole } from '../Console/Console';
import { TbList, TbUnderline, TbItalic, TbBold, TbHeading, TbSearch } from 'react-icons/tb';

import { getDocument, updateDocument } from '../../utils/dbUtils';

let root = document.documentElement;
var themeColor = 'light';

const customStyleMap = {
  STRIKETHROUGH: {
    textDecoration: "line-through"
  },
  FONT_SIZE_30: {
    fontSize: "30px"
  }
};

let saveTimer;

export const App = (params) => {
  const [state, setState] = useState({
    editorState: EditorState.createEmpty()
  });
  const [data, setData] = useState({});
  const [title, setTitle] = useState('');

  useEffect(() => {
    const content = window.localStorage.getItem('content');
    if (params.docId) {
      getDocument(params.docId).then((doc) => {
        if (doc) {
          setData(doc);
          setTitle(doc.title);
          setState({editorState: EditorState.moveFocusToEnd(EditorState.createWithContent(convertFromRaw(JSON.parse(doc.content))))});
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    } else {
      // setState({editorState: EditorState.createEmpty()});
      setState({editorState: EditorState.moveFocusToEnd(EditorState.createEmpty())});
    }
    document.title = title;
  }, []);
  
  const onChange = editorState => {
    clearTimeout(saveTimer);
    const contentState = editorState.getCurrentContent();

    if (document.activeElement.className === 'notranslate public-DraftEditor-content') {
      document.getElementById('styling').style.display = "flex";
    }
    else {
      document.getElementById('styling').style.display = "none";
    }

    window.localStorage.setItem('content', JSON.stringify(convertToRaw(contentState)));
    setState({editorState});
    saveTimer = setTimeout(() => {
      saveContent(contentState);
    }, 2000);
  };

  const saveContent = (newContent) => {
    if (params.docId) {
      updateDocument(params.docId, JSON.stringify(convertToRaw(newContent)), title, data)
      // .then((doc) => {})
      .catch((error) => {
        console.log("Error updating document:", error);
      }
      );
    }
  }

  const keyBindingFn = (event) => {
    // if (event.keyCode === 191) { return 'terminal'; } // Maybe for inline functions like Notion, Confluence, etc.
    // we use hasCommandModifier instead of checking for CTRL keyCode because different OSs have different command keys
    // could use metaKey instead of ctrlKey
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 66) { return 'bbbold'; } // b
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 73) { return 'italics'; } // i
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 85) { return 'underline'; } // u
    // if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 77) { return 'theme'; } // m <- wouldn't work on mac
    // manages usual things, like:
    // Ctrl+Z => return 'undo'
    return getDefaultKeyBinding(event);
  }

  // command: string returned from keyBidingFn(event)
  // if this function returns 'handled' string, all ends here.
  // if it return 'not-handled', handling of :command will be delegated to Editor's default handling.
  const handleKeyCommand = (command) => {
    let newState;
    if (command === 'bbbold') {
      newState = RichUtils.toggleInlineStyle(state.editorState, 'BOLD');
    }

    if (command === 'italics') {
      newState = RichUtils.toggleInlineStyle(state.editorState, 'ITALIC');
    }

    if (command === 'underline') {
      newState = RichUtils.toggleInlineStyle(state.editorState, 'UNDERLINE');
    }

    if (command === 'terminal') {
      console.log("console launch");
    }

    if (command === 'theme') {
      if (themeColor === "light") {
        root.style.setProperty("--color-primary", 'black');
        root.style.setProperty("--color-accent", '#131313');
        root.style.setProperty("--color-font", 'white');
        themeColor = 'dark';
      } 
      else {
        root.style.setProperty("--color-primary", 'white');
        root.style.setProperty("--color-accent", '#F0F0F0');
        root.style.setProperty("--color-font", 'black');
        themeColor = 'light';
      }
    }

    if (newState) {
      setState({ editorState: newState });
      return 'handled';
    }
    return 'not-handled';
  }

  const temp = document.body.innerHTML;
  window.addEventListener("beforeprint", (event) => {
    console.log("Before print");
    // remove all elements and replace with empty html
    document.body.innerHTML = convertToRaw(state.editorState.getCurrentContent()).blocks.map((block) => block.text).join('\n');
  });

  window.addEventListener("afterprint", (event) => {
    console.log("After print");
    // replace with original html
    document.body.innerHTML = temp;
  });

  const _onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(state.editorState, 'BOLD'));
  }

  const _onItalicClick = () => {
    onChange(RichUtils.toggleInlineStyle(state.editorState, 'ITALIC'));
  }

  const _onUnderlineClick = () => {
    onChange(RichUtils.toggleInlineStyle(state.editorState, 'UNDERLINE'));
  }

  const _onListClick = () => {
    onChange(RichUtils.toggleBlockType(state.editorState, 'unordered-list-item'));
  }

  const _onTitleClick = () => {
    onChange(RichUtils.toggleBlockType(state.editorState, 'header-two'));
  }

  // doesnt fire on click weird es6 things
  const _onSearchClick = () => {
    showConsole();
    console.log("search");

    // document.documentElement.style.setProperty("--disp", 'flex');

    // document.activeElement.blur();
    // setTimeout(function() { document.getElementById("console").focus() }, 20);
    // root.style.setProperty("--disp", 'flex');
  }

  const onTogglePopover = (e) => {
    e.preventDefault();
  }

  return (
    <div className="editor">
      <div id="styling">
          <div id="buttons">
            <button onMouseDown={onTogglePopover} onClick={_onBoldClick}>
              <TbBold className="material-icons" />
            </button>
            <button onMouseDown={onTogglePopover} onClick={_onItalicClick}>
              <TbItalic className="material-icons" />
            </button>
            <button onMouseDown={onTogglePopover} onClick={_onUnderlineClick}>
              <TbUnderline className="material-icons" />
            </button>
            <button onMouseDown={onTogglePopover} onClick={_onListClick}>
              <TbList className="material-icons" />
            </button>
            <button onMouseDown={onTogglePopover} onClick={_onTitleClick}>
              <TbHeading className="material-icons" />
            </button>
            <button onClick={showConsole}>
              <TbSearch className="material-icons" />
            </button>
          </div>
      </div>
      <Editor
        editorState={state.editorState}
        onChange={onChange}
        customStyleMap={customStyleMap}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={keyBindingFn}
        />
    </div>
  );
}

export default App;