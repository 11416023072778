let root = document.documentElement;

const dark = () => {
    root.style.setProperty('--color-primary', 'black');
    root.style.setProperty('--color-accent', '#131313');
    root.style.setProperty('--color-font', 'white');
    root.style.setProperty('--color-secondary-font', '#ffffff77');
    saveTheme('dark');
}

const light = () => {
    root.style.setProperty('--color-primary', 'white');
    root.style.setProperty('--color-accent', '#F0F0F0');
    root.style.setProperty('--color-font', 'black');
    root.style.setProperty('--color-secondary-font', '#00000077');
    saveTheme('light');
}

var themeColor = window.localStorage.getItem('theme');

export const swapTheme = (arg) => {
    switch (arg) {
        case 'dark':
            dark();
            break;
        case 'light':
            light();
            break;
        default:
            if (themeColor === 'dark') {
                root.style.setProperty('--color-primary', 'white');
                root.style.setProperty('--color-accent', '#F0F0F0');
                root.style.setProperty('--color-font', 'black');
                root.style.setProperty('--color-secondary-font', '#00000077');
                saveTheme('light');
            } else {
                root.style.setProperty('--color-primary', 'black');
                root.style.setProperty('--color-accent', '#131313');
                root.style.setProperty('--color-font', 'white');
                root.style.setProperty('--color-secondary-font', '#ffffff77');
                saveTheme('dark');
            }
            break;
    }
}
    
const saveTheme = (theme) => {
    window.localStorage.setItem('theme', theme);
    themeColor = theme;
}

export const initalTheme = () => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    if (!themeColor) {
        if (darkThemeMq.matches) {
            swapTheme('dark');
        } else {
            swapTheme('light');
        }
    }
}