import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';

import "@fontsource/montserrat"
import "@fontsource/inter"


import './index.css';
import { Layout } from './layout';
import { Reset } from './pages/reset/Reset';
import { Docs } from './pages/docs/Docs';
import { Editor } from 'draft-js';

ReactDOM.render(
    <Router>
      <Switch>
        <Route exact path="/">
          <Layout />
        </Route>
        <Route path="/forgot-password">
          <Reset />
        </Route>
        <Route path="/docs">
          <Docs />
        </Route>
      </Switch>
    </Router>,
  document.getElementById('root')
);