import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router';

import { initalTheme } from '../../components/Swap/SwapUtils';
import { auth, currentUserId } from '../../utils/firebase-init';

import { App } from '../../components/Editor/App';
import { Swap } from '../../components/Swap/Swap';
import { Console } from '../../components/Console/Console';

export const Editor = () => {
    initalTheme();

    const { docId } = useParams();

    return (
        <React.Fragment>
            <App {...{ docId }} />
            <Swap />
            <Console />
        </React.Fragment>
    )
}