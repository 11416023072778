import React from 'react'

import { Editor } from './pages/editor/Editor'
import { Login } from './pages/login/Login'

import { auth } from "./utils/firebase-init"
import { useAuthState } from 'react-firebase-hooks/auth';

export const Layout = () => {
    const [user, loading, error] = useAuthState(auth);

    if (user) {
        window.location.href = '/docs';
    }

    return (
        <React.Fragment>
            { loading && <h1>Loading...</h1> }
            {/* { user && <Editor /> } */}
            { !user && !loading && <Login /> }
        </React.Fragment>
    )
}