import './Console.css';
import React, { useEffect } from 'react';

import { swapTheme } from '../Swap/SwapUtils';
import { getAuth, signOut } from 'firebase/auth';

let root = document.documentElement;
let commands = {'/exit': 0, '/all': 0, '/signout': 0, '/themes': ['toggle', 'dark', 'light'], '/login': 0, '/files': 0, '/import': 0, '/export': 0, '/print': 0, '/toolbox': 0, '/commands': 0, '/tips': 0, '/help': 0}

export const showConsole = () => {
  document.activeElement.blur();
  root.style.setProperty("--disp", 'flex');
  setTimeout(function() { document.getElementById("console").focus() }, 20);
}

export const Console = () => {
  useEffect(() => {
    document.onkeydown = keyDownEvent;
    let shown = false;
    let focus = 'console';
    function keyDownEvent(e) {
      // Ctrl/Cmd + j
      if ((e.ctrlKey || e.metaKey) && e.key === 'j') {
        e.preventDefault();
        if (!shown) {
          focus = document.activeElement;
          focus.blur();
          setTimeout(function() { document.getElementById("console").focus() }, 20);
          root.style.setProperty("--disp", 'flex');
        } else {
          document.getElementById("console").value = "";
          document.getElementById("autocomplete").innerHTML = "";
          root.style.setProperty("--disp", 'none');
          document.activeElement.blur();
          setTimeout(function() { focus.focus() }, 20);
        }
        shown = !shown;
    }

    // Esc
    if (e.keyCode === 27 && shown) {
      e.preventDefault();
      document.getElementById("console").value = "";
      document.getElementById("autocomplete").innerHTML = "";
      root.style.setProperty("--disp", 'none');
      document.activeElement.blur();
      setTimeout(function() { focus.focus() }, 20);
      shown = !shown;
    }

    // Ctrl/Cmd + Shift + a
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'a') {
      e.preventDefault();
      swapTheme('toggle');
    }

    // Tab or Right Arrow
    if ((e.keyCode === 9 || e.keyCode === 39) && shown) {
      e.preventDefault();
      if (document.getElementById("autocomplete").innerHTML !== "") {
        // document.getElementById("autocomplete").className = "fade-text";
        setTimeout(function() { 
          // Right now the autocomplete will just continue behind the form so incomplete secondaries have spaces
          // /themes /th will add a space after /th upon autocomplete
          // need to move autocomplete field or ignore the existing suggestion if it matches form value
          let value = commands[document.getElementById("autocomplete").innerText];
          if (value === 0 || value === undefined) {
            document.getElementById("console").value = document.getElementById("autocomplete").innerText;
          } else {
            document.getElementById("console").value = document.getElementById("autocomplete").innerText + " ";
          }
          document.getElementById("autocomplete").innerHTML = "";
          document.getElementById("autocomplete").className = "";
        }, 150)
      }
    }

    // Enter
    if (e.keyCode === 13 && shown) {
      e.preventDefault();
      if (Object.keys(commands).includes(document.getElementById("console").value.split(" ")[0])) {
        const command = document.getElementById("console").value.split(" ");
        switch (command[0]) {
          case '/all':
            if (command[1]) {
              error();
              break;
            }
            console.log("all");
            break;
          case '/exit':
            console.log(window.location.pathname);
            window.location.pathname.includes('/docs/') ? window.location.pathname = '/docs' : window.close();
            break;
          case '/themes':
            console.log("themes");
            if (command[1] === "") {
              swapTheme('toggle');
              break;
            }
            command[1] = command[1].toLowerCase();
            (commands['/themes'].includes(command[1])) ? swapTheme(command[1]) : error();
            break;
          case '/login':
            document.activeElement.blur();
            break;
          case '/signout':
            document.activeElement.blur();
            shown = false;
            document.getElementById("console").value = "";
            document.getElementById("autocomplete").innerHTML = "";
            root.style.setProperty("--disp", 'none');
            const auth = getAuth();
            signOut(auth).then(() => {
            }).catch((error) => {
              // An error happened.
            });
            break;
          case '/files':
            console.log("files");
            break;
          case '/import':
            console.log("import");
            break;
          case '/export':
            console.log("export");
            break;
          case '/print':
            try {
              // Print for Safari browser
              document.execCommand('print', false, null)
            } catch {
              window.print()
            }
            break;
          case '/toolbox':
            console.log("toolbox");
            break;
          case '/commands':
            console.log("commands");
            break;
          case '/tips':
            console.log("tips");
            window.open('/tips.html', '_blank');
            break;
          case '/help':
            console.log("help");
            break;
          default:
            console.log("default");
        }
      } else {
        error();
      }
    }

    function error() {
      document.getElementById("console-bar").className = "error";
      setTimeout(function() { document.getElementById("console-bar").className = "" }, 500);
    }
  };

    document.body.addEventListener('click', function (event) {
      try {
        if (document.getElementById("console").contains(event.target) || document.getElementById("options").contains(event.target)) {
        } else {
          shown = false;
          document.getElementById("console").value = "";
          document.getElementById("autocomplete").innerHTML = "";
          root.style.setProperty("--disp", 'none');
        }
      } catch (error) {

      }
  });

}, [commands]);

  // const onChange = editorState => {const contentState = editorState.getCurrentContent(); sendQue(contentState); setState({editorState})};

  
  const sendQue = (event) => {
    // remove .toUpperCase for case sensitive
    // setState({value: event.target.value});
    var input;
    input = document.getElementById("console");
    if (input.value !== "") {
      // function filter(length) {
      //   return input.value.toUpperCase().slice(0, length);
      // }
      // var txt = input.value.toUpperCase();
      // var ul = document.getElementById("options");
      // var li = ul.getElementsByTagName("li");
      // ul.style.display = "none";
      // if (input.value === "/all") {
      //   var link = document.createElement('a');
      //   var entry = document.createElement('li');
      //   entry.style.display = "grid";
      //   link.appendChild(document.createTextNode("all"));
      //   link.href = "/";
      //   entry.appendChild(link);
      //   ul.appendChild(entry);
      //   ul.style.display = "grid";
      // }
      // else {
      //   for (i = 0; i < li.length; i++) {
      //       li = li[i]
      //       txtValue = li.textContent || li.innerText;
      //       if (txtValue.substr(0, txt.length).toUpperCase() === txt) {
      //           li.style.display = "grid";
      //           ul.style.display = "grid";
      //           // console.log('not empty!');
      //       } else {
      //           li.style.display = "none";
      //       }
      //   }
      // }
    }
    else {
      document.getElementById("options").style.display = "none";
      document.getElementById("autocomplete").innerHTML = '';
      // console.log('empty!');
    }
  }

  const keyUp = (event) => {
    let input = document.getElementById("console").value;
    input = input.toLowerCase();
    if(input.length > 0 ) {
      document.getElementById("autocomplete").innerHTML = input;
      let regex = new RegExp('^' + input.split(' ')[0] + '.*', 'i');
          for(let i = 0; i < Object.keys(commands).length; i++){
              let command = Object.keys(commands)[i];
              if(command.match(regex)){
                document.getElementById("autocomplete").innerHTML += "<span>" + command.slice(input.length, command.length+1) + "</span>";
                if (input.length > command.length) {
                  autoComplete(input.replace((command + ' '), ''), i);
                }
                break;
              }
          }
    }
   }

   const keyDown = (event) => {
    if(document.getElementById("console").value.length <= 1 && event.keyCode === 8){
      // document.getElementById("autocomplete").innerHTML = '';
     }
   }

   const autoComplete = (input, index) => {
      let regex = new RegExp('^' + input + '.*', 'i');
      let command = Object.values(commands)[index];
      if (command !== 0) {
        for(let i = 0; i < command.length; i++){
          if(command[i].match(regex)) {
            document.getElementById("autocomplete").innerHTML += "<span>" + command[i].slice(input.length, command[i].length) + "</span>";
            break;
          }
        }
      } 
    }



  return (
      <form id="console-cont">
        <div id="console-bar">
          <div id="autocomplete"></div>
          <input id="console" type="text" 
              contentEditable="true"
              aria-placeholder="Use a component or /commands"
              placeholder="Use a component or /commands"
              autoFocus autoComplete="off"
              onChange={sendQue}
              onKeyDown={keyDown}
              onKeyUp={keyUp}
              >
          </input>
        </div>
        <ul id="options">
          <li>/themes (WIP)</li>
          <li>/login (WIP)</li>
          <li>/files (WIP)</li>
          <li>/print (WIP)</li>
          <li>/import (WIP)</li>
          <li>/export (WIP)</li>
          <li>/toolbox (WIP)</li>
          <li>/commands (WIP)</li>
          {/* <li><a href="./tips.html">/tips (WIP)</li> */}
          <li>/tips (WIP)</li>
          <li>/all (WIP)</li>
        </ul>
      </form>
  );
}