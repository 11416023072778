import * as firebase from 'firebase/app';
import { getAuth, initializeAuth, inMemoryPersistence, indexedDBLocalPersistence, browserLocalPersistence } from "firebase/auth";
// import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore, collection, setDoc, initializeFirestore } from "firebase/firestore";

export const currentUserId = () => {
  const user = auth.currentUser;
  if (user) {
    return user.uid;
  } else {
    return null;
  }
}

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_PUBLIC_API_KEY,
//   authDomain: process.env.REACT_APP_PUBLIC_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_PUBLIC_DATABASE_URL,
//   projectId: process.env.REACT_APP_PUBLIC_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_PUBLIC_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_PUBLIC_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_PUBLIC_APP_ID,
//   measurementId: process.env.REACT_APP_PUBLIC_MEASUREMENT_ID
// };

const firebaseConfig = {
  apiKey: "AIzaSyA7zJqjgrys_UG0O3yEPy0onNCnM3x8pr4",
  authDomain: "motrou-65ad9.firebaseapp.com",
  databaseURL: "https://motrou-65ad9.firebaseio.com",
  projectId: "motrou-65ad9",
  storageBucket: "motrou-65ad9.appspot.com",
  messagingSenderId: "765740243933",
  appId: "1:765740243933:web:b1b6d5acb648df1f37b434",
  measurementId: "G-DDHQJGSFRR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});
// const databaseRef = app.database().ref();
// export const docsRef = databaseRef.child("docs");

export default firebase;