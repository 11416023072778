import React, { useEffect, useCallback } from "react";
import { UlidMonotonic } from "id128";
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { TbTrash } from "react-icons/tb";

import { Swap } from "../../components/Swap/Swap";
import { Logo } from "../../components/Logo/Logo";
import { Console } from "../../components/Console/Console";
import { Editor } from "../editor/Editor";

import { initalTheme } from "../../components/Swap/SwapUtils";
import { addDoc, getUserDetails, setDeleted, setTitle } from "../../utils/dbUtils";
import { auth, currentUserId } from "../../utils/firebase-init";

import "./Docs.css";

export const Docs = () => {
    const [user, loading, error] = useAuthState(auth);
    const [info, setInfo] = React.useState();
    const [refreshed, setRefreshed] = React.useState(0);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (!info) {
                getUserDetails(user.uid).then((data) => {
                    setInfo(data)
                }).catch((error) => {
                    console.log("ERROR", error);
                });
            }
        });
    }, [info]);

    initalTheme();

    if (!loading && !user) {
        window.location.href = '/';
    }


    const timeSincetimecode = (timecode) => {
        const time = new Date(timecode);
        const now = new Date();
        const diff = now - time;
        const diffSeconds = Math.round(diff / 1000);
        const diffMinutes = Math.round(diff / (1000 * 60));
        const diffHours = Math.round(diff / (1000 * 60 * 60));
        const diffDays = Math.round(diff / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.round(diff / (1000 * 60 * 60 * 24 * 7));
        const diffMonths = Math.round(diff / (1000 * 60 * 60 * 24 * 30));
        const diffYears = Math.round(diff / (1000 * 60 * 60 * 24 * 365));
        if (diffSeconds < 10) {
            return "just now";
        } else if (diffSeconds < 60) {
            return diffSeconds + (diffSeconds > 1 ? " seconds ago" : " second ago");
        } else if (diffMinutes < 60) {
            return diffMinutes + (diffMinutes > 1 ? " minutes ago" : " minute ago");
        } else if (diffHours < 24) {
            return diffHours + (diffHours > 1 ?  " hours ago" : " hour ago");
        } else if (diffDays < 7) {
            return diffDays + (diffDays > 1 ? " days ago" : " day ago")
        } else if (diffWeeks < 4) {
            return diffWeeks + (diffWeeks > 1 ? " weeks ago" : " week ago")
        } else if (diffMonths < 12) {
            return diffMonths + (diffMonths > 1 ? " months ago" : " month ago")
        } else {
            return diffYears + (diffYears > 1 ? " years ago" : " year ago")
        }
    }

    const openDoc = (docId) => {
        window.location.href = `/docs/${docId}`;
    }

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Route path="/docs/:docId">
                        <Editor />
                    </Route>
                    <Route path="/docs">
                        <Logo />
                        <Swap />
                        <Console />
                        <button id="addDoc" onClick={() => {addDoc(true).then(() => {setRefreshed(Math.random())}); console.log(refreshed);}}>new doc</button>
                        <div className="docs">
                            <h1>Docs</h1>
                            <div className="docsContainer">
                                <div className="docsList">
                                    { info && info.docs.map((doc) => {
                                        if (!doc.deleted) {
                                            return (
                                                <div onClick={(e) => {
                                                    if (e.target.id !== "edittitle") {
                                                        openDoc(doc.id)
                                                    }
                                                    }} className="doc" key={doc.id}>
                                                    <div className="docHeader">
                                                        <TbTrash className="deleteDoc" onClick={(e) => {e.stopPropagation(); e.preventDefault(); setDeleted(doc).then(() => {setRefreshed(Math.random()); });}} />
                                                        <form id="editTitle" onSubmit={(e) => {
                                                            e.stopPropagation()
                                                            e.preventDefault()
                                                            // const title = document.getElementById("edittitle").value
                                                            // if (doc.title !== title) {
                                                            //     setTitle(title, doc)
                                                            //         .then(() => {
                                                            //             doc.title = title
                                                            //             setRefreshed(Math.random())
                                                            //         })
                                                            //     }
                                                                document.activeElement.blur()
                                                            }
                                                        }>
                                                            <input type="text" name="edittile" autoComplete="off" defaultValue={doc.title} id='edittitle' onBlur={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                setTimeout(() => {
                                                                    const title = document.getElementById("edittitle").value
                                                                    if (doc.title !== title) {
                                                                        setTitle(title, doc)
                                                                            .then(() => {
                                                                                doc.title = title
                                                                                setRefreshed(Math.random())
                                                                            })
                                                                        }
                                                                        document.activeElement.blur()
                                                                }, 200)
                                                                }
                                                            }/>
                                                        </form>
                                                        {/* <h2 title={doc.title}>{doc.title}</h2> */}
                                                    </div>
                                                    <div className="docBody">
                                                    </div>
                                                    <p>{timeSincetimecode(doc.updated)}</p>
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </React.Fragment>
    )
}