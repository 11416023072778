import React, { Fragment } from "react";

import './Reset.css';
import { initalTheme } from '../../components/Swap/SwapUtils';
import Swap from "../../components/Swap/Swap";
import Logo from "../../components/Logo/Logo";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const Reset = () => {
    initalTheme();

    const handleSubmit = (e) => {
        e.preventDefault();
        const auth = getAuth();
        if (document.getElementById('email').value === '') {
            document.getElementById('error').innerHTML = 'email required';
        } else {
            sendPasswordResetEmail(auth, document.getElementById('email').value)
            .then(() => {
                document.getElementById('reset-form').innerHTML = 'check your email for a link to reset your password.';
                setTimeout(function() { window.location.href = '/'; }, 2000);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                document.getElementById('error').innerHTML = 'try another email';
            });
        }
    }
    return (
        <Fragment>
            <Logo />
            <Swap />
            <div className='reset'>
                <form id='reset-form' onSubmit={(e) => {handleSubmit(e)}}>
                    <input id="email" placeholder="email" type="email" />
                    <button>send reset</button>
                    <div id='error'></div>
                </form>
            </div>
        </Fragment>
    )
}